/*
 * @Author: SongYijie
 * @Date: 2020-03-18 13:41:00
 * @LastEditors: SongYijie
*/

let record = {
  comnpanyName: '杭州中僖创智企业管理咨询有限公司',
  icpNo: '浙ICP备19008332号'
};

const { origin } = window.location;
if (origin.indexOf('https://h5.xiyk.cn') > -1) {
  record.comnpanyName = '杭州中僖创智信息技术有限公司';
  record.icpNo = '浙ICP备19032098号-1';
} else if (origin.indexOf('https://m.xiydr.cn') > -1) {
  record.comnpanyName = '杭州中僖创智信息技术有限公司';
  record.icpNo = '浙ICP备19032098号-3';
}

export default record;