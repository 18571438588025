<template>
  <div v-if="!loading">
    <div class="logo-area">
      <img v-if="!isXyk" class="logo" alt="logo" src="@assets/xyk_logo.png" />
      <img v-else class="logo" alt="logo" src="@assets/ql_logo.png" />
      <h1 class="txt-black">共享经济综合服务平台</h1>
    </div>
    <div class="login-form">
      <div class="line clearfix">
        <div class="col col-left txt-black">
          +86
          <span class="txt-gray-lighter">|</span>
        </div>
        <div class="col col-mid">
          <input
            style="border: none; background: transparent"
            type="text"
            placeholder="请输入手机号"
            v-model="phoneNum"
            v-on:blur="checkPhone"
            maxlength="11"
          />
        </div>
        <span
          class="col col-right txt-red"
          @click="throttleSendVeriFn"
          v-if="seconds == 0"
          >发送验证码</span
        >
        <span class="col col-right txt-red" v-else>{{ seconds }}秒</span>
      </div>
      <div v-if="err.phone" class="txt-red err-info">
        <i class="iconfont icon-info-circle"></i>
        {{ err.phone }}
      </div>
      <div class="line">
        <input
          style="border: none; background: transparent"
          type="text"
          placeholder="请输入验证码"
          size="6"
          v-model="code"
          v-on:blur="checkCode"
          maxlength="6"
        />
      </div>
      <div v-if="err.code" class="txt-red err-info">
        <i class="iconfont icon-info-circle"></i>
        {{ err.code }}
      </div>
      <div class="check-info">
        <label class="mint-radiolist-label" @click="handleToggleAgreementFlag">
          <span class="mint-radio">
            <!-- <input type="radio" class="mint-radio-input" value="true" v-model="agreementFlag" /> -->
            <span class="mint-radio-core" v-if="!agreementFlag"></span>
            <span
              class="mint-radio-core mint-radio-core-activity"
              v-else-if="agreementFlag"
            ></span>
          </span>
          <span class="mint-radio-label">
            我已同意
            <a class="agreement" @click="toCheckFn"
              >《{{
                isXyk ? "麒麟云服" : "僖游客"
              }}平台用户隐私协议及注册协议》</a
            >
          </span>
        </label>
      </div>
      <mt-button type="danger" size="large" @click="loginFn">登录</mt-button>
      <div v-if="!isXyk" class="copyright">
        {{ `Copyright © 2019 ${record.comnpanyName} ` }}
        <a
          href="http://www.beian.miit.gov.cn"
          target="_blank"
          style="color: rgba(0, 0, 0, 0.45)"
          rel="noopener"
          >{{ record.icpNo }}</a
        >
      </div>
    </div>
  </div>
</template>

<script>
import { verifyPhone, verifyStr, throttle, getParams } from "@utils/common";
import { getVeriCode, loginning, inputIdNum } from "@api/login";
import { setInterval, clearInterval } from "timers";
import store from "@/store.js";
import { MessageBox, Toast, Indicator } from "mint-ui";
import record from "@utils/record.js";
import { isWeChatBrowser } from "@utils/weChatAuthorization";
import { getWeChatAuthorization } from "@utils/weChatAuthorization";
import md5 from "js-md5";
let timer;
export default {
  components: {},
  data() {
    return {
      err: {
        phone: "",
        code: "",
      },
      phoneNum: "",
      code: "",
      seconds: 0,
      agreementFlag: false,
      record,
      loading: true,
      isXyk: window.location.origin.indexOf("h5.kylincloud-saas") > -1,
      isWeixin: false,
      weixinCode: "",
    };
  },
  created() {
    Indicator.open("授权中...");
    const timer = setTimeout(() => {
      Indicator.close();
      this.loading = false;
      clearTimeout(timer);
    }, 1500);
    getWeChatAuthorization().then((res) => {

      this.loading = false;
      Indicator.close();
      if (!res) {
        if (isWeChatBrowser()) {
          this.getCode();
        }
        return;
      }
      if (res.openid) {
        this.$store.commit("setWxInfo", res);
        sessionStorage.setItem("setWxInfo", JSON.stringify(res));
      } else {
        this.getCode();
        this.$store.commit("setUser", res);
        sessionStorage.setItem("user", JSON.stringify(res));
        const urlData = getParams(window.location.href);
        if (urlData.comer) {
          this.$router.push("/UploadVideo/index");
          return;
        }
        if (res.state === 2) {
          this.$router.replace("/taskCenter");
        } else {
          if (res.idCardType === 1 || res.idCardType === null) {
            this.$router.replace("/hotTip");
          } else {
            this.$router.replace("/user/confirmApplyUser");
          }
        }
      }
    });
  },
  mounted() {
    console.log(this.getUrlCode().code);
    const isNum = window.localStorage.getItem("isNum");
    if (!isNum) {
      window.localStorage.setItem("isNum", 0);
    }
    if (isWeChatBrowser()) {
      this.getCode();
    }
  },
  beforeDestroy() {
    clearInterval(timer);
    const messageBox = document.getElementsByClassName(
      "mint-msgbox-btn mint-msgbox-cancel "
    );

    if (messageBox && messageBox[0]) {
      messageBox[0].click();
    }
  },
  methods: {
    getCode() {
      // 非静默授权，第一次有弹框
      this.weixinCode = "";
      // let local = window.location.origin; // 获取页面url
      let appid = "wx0b6e7d6f8f4457d7";
      const isNum = window.localStorage.getItem("isNum");
      if (!Number(isNum)) {
        console.log('重新获取code')
        window.localStorage.setItem("isNum", 1);
        // 如果没有code，则去请求
        window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=https://h5.xiyk.cn&response_type=code&scope=snsapi_userinfo&state=123#wechat_redirect`;
      }
    },
    getUrlCode() {
      // 截取url中的code方法
      var url = location.search;
      this.winUrl = url;
      var theRequest = new Object();
      if (url.indexOf("?") != -1) {
        var str = url.substr(1);
        var strs = str.split("&");
        for (var i = 0; i < strs.length; i++) {
          theRequest[strs[i].split("=")[0]] = strs[i].split("=")[1];
        }
      }
      return theRequest;
    },
    handleShowIdMsg() {
      MessageBox.prompt("请输入身份证号码，完善用户信息", "请输入身份证号")
        .then(({ value, action }) => {
          window.scroll(0, 0);
          if (action === "confirm") {
            inputIdNum({
              phone: this.phoneNum,
              idNum: value,
            }).then((res) => {
              Toast(res.data.message);
            });
          }
        })
        .catch((err) => {
          window.scroll(0, 0);
        });
    },
    handleToggleAgreementFlag() {
      this.agreementFlag = !this.agreementFlag;
    },
    checkPhone() {
      // 检测手机号码合法性
      window.scroll(0, 0);
      let verifyInfo = verifyPhone(this.phoneNum);
      this.err.phone = verifyInfo.done ? "" : verifyInfo.errMsg;
    },
    checkCode() {
      // 检测验证码
      window.scroll(0, 0);
      let verifyInfo = verifyStr(this.code, "验证码");
      this.err.code = verifyInfo.done ? "" : verifyInfo.errMsg;
    },
    throttleSendVeriFn: throttle(function () {
      this.sendVeriFn();
    }, 2000),
    sendVeriFn() {
      this.err.phone = "";
      if (this.phoneNum == "") {
        this.err.phone = "请输入手机号码";
        return;
      }
      if (!verifyPhone(this.phoneNum).done) {
        return;
      }
      const key = md5(`${md5(`${this.phoneNum}`)}zhongxicz`);
      let data = {
        phone: this.phoneNum,
        key,
      };
      getVeriCode(data).then((res) => {
        const { data } = res;
        if (data.code === 200) {
          // 验证码跑秒
          let secondsNum = 20;
          var that = this;
          let secondsInter = setInterval(() => {
            if (secondsNum == 0) {
              //结束跑秒
              that.seconds = 0;
              clearInterval(secondsInter);
            }
            that.seconds = secondsNum;
            secondsNum--;
          }, 1000);
        } else if (data.code === 2001) {
          this.handleShowIdMsg();
        } else {
          this.err.phone = data.message;
        }
      });
    },
    loginFn() {
      //登录
      this.checkPhone();
      this.checkCode();
      if (this.err.phone !== "" || this.err.code !== "") return;
      if (!this.agreementFlag) {
        MessageBox.alert(
          `请阅读并同意《${
            isXyk ? "麒麟云服" : "僖游客"
          }平台用户隐私协议及注册协议》后再登录`,
          ""
        );
        return;
      }
      let data = {
        phone: this.phoneNum,
        code: this.code,
        weixinCode: this.weixinCode,
        ...this.$store.getters.getWxInfo,
      };
      // 登录接口
      loginning(data)
        .then((res) => {
          window.localStorage.setItem("isNum", 0);
          if (res.data.code != 200) {
            this.err.code = res.data.message;
            this.$store.commit("removeStorage");
            return;
          }
          let user = { ...res.data.data };
          user.phone = this.phoneNum;
          this.$store.commit("setUser", user);
          sessionStorage.setItem("user", JSON.stringify(user));
          const urlData = getParams(window.location.href);
          if (urlData.comer) {
            this.$router.push("/UploadVideo/index");
            return;
          }
          if (user.state === 2) {
            this.$router.replace("/taskCenter");
          } else {
            if (user.idCardType === 1 || user.idCardType === null) {
              this.$router.push("/hotTip");
            } else {
              this.$router.push("/user/confirmApplyUser");
            }
          }
        })
        .catch((err) => {});
    },
    toCheckFn(e) {
      e.cancelBubble = true;
      this.$router.push("/login/agreement");
    },
  },
};
</script>

<style lang="less" scope>
.logo-area {
  text-align: center;
  position: relative;
  padding-top: 38px;
  margin-bottom: 20px;
  .logo {
    width: 10rem;
    margin: auto;
  }
  h1 {
    font-size: 1.35rem;
    position: absolute;
    bottom: 15px;
    text-align: center;
    display: block;
    width: 100%;
    font-family: PingFangSC-Semibold, sans-serif;
  }
}

.mint-radio-core-activity {
  background-color: #ffffff;
  border-color: #26a2ff;
  border-width: 6px;
  box-sizing: border-box;
}

.login-form {
  padding: 0 20px;
  .line {
    border-bottom: 1px solid #ddd;
    margin-top: 8px;
    margin-bottom: 2px;
    height: 35pt;
    line-height: 35pt;
    position: relative;
    .col {
      white-space: nowrap;
      float: left;
      font-size: 1rem;
      &.col-left {
        width: 2.5rem;
        span {
          display: inline-block;
          padding-bottom: 2px;
        }
      }
      &.col-mid {
        position: absolute;
        top: 0;
        bottom: 1px;
        left: 2.7rem;
        right: 5.3rem;
      }
      &.col-right {
        float: right;
        width: 5.3rem;
        text-align: right;
      }
    }
    input {
      font-size: 1rem;
      width: 100%;
      height: 87%;
    }
  }
  .err-info {
    font-size: 0.9rem;
  }
  .mint-radiolist-label {
    padding: 0;
    display: block;
    height: 2.25rem;
    line-height: 1.25rem;
  }
  .check-info {
    white-space: nowrap;
    padding: 8px 0;
    // line-height: 2.5rem;
    // margin-bottom: 10px;
    font-size: 0.75rem;
    color: #999;
    .agreement {
      text-decoration: underline;
    }
  }
}

.copyright {
  display: none;
  position: fixed;
  left: 0;
  bottom: 3.2vw;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  text-align: center;
  width: 100%;
}
@media screen and (min-width: 1000px) {
  .copyright {
    display: block;
  }
}
</style>
